import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getItemsAsync } from "../redux/items/thunks";
import ItemRow from "./ItemRow";
import Filters from "./Filters";

const ItemList = () => {
    const items = useSelector((state) => state.items.list);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getItemsAsync(""));
    }, []);

    return (
        <>
            <Filters />
            {items.map((item) => (
                <ItemRow key={item._id} item={item} />
            ))}
        </>
    );
};

export default ItemList;
