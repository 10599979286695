import { useState } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch } from "react-redux";
import { getItemsAsync } from "../redux/items/thunks";

const Filters = () => {
    const [origin, setOrigin] = useState("");
    const dispatch = useDispatch();

    const handleChange = async (event) => {
        const originValue = event.target.value;
        setOrigin(originValue);
        await dispatch(getItemsAsync(originValue));
    };

    return (
        <Box
            sx={{
                marginBottom: "10px",
            }}
        >
            <FormControl fullWidth>
                <Select
                    sx={{
                        border: "2px solid black",
                        backgroundColor: "white",
                        width: "240px",
                    }}
                    labelId="select-label"
                    value={origin}
                    label="Origin"
                    displayEmpty={true}
                    onChange={handleChange}
                >
                    <MenuItem sx={{ color: "grey" }} value={""}>
                        <em>Select Country of Origin</em>
                    </MenuItem>
                    <MenuItem value={"South Korea"}>South Korea</MenuItem>
                    <MenuItem value={"Canada"}>Canada</MenuItem>
                    <MenuItem value={"USA"}>USA</MenuItem>
                    <MenuItem value={"Others"}>Others</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
};

export default Filters;
