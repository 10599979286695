import React, { useState } from "react";
import "../styles/Style.css";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import {
    getItemsAsync,
    deleteItemAsync,
    editItemAsync,
} from "../redux/items/thunks";
import Wrapper from "../styles/Wrapper";
import EditForm from "./EditForm";

const ItemRow = ({ item }) => {
    const [editing, setEditing] = useState(false);
    const dispatch = useDispatch();

    const handleEdit = () => {
        editing ? setEditing(false) : setEditing(true);
    };

    const handleSave = async (updatedItem) => {
        await dispatch(editItemAsync(updatedItem));
        await setEditing(false);
        await dispatch(getItemsAsync(""));
    };

    const handleDelete = async (item) => {
        await dispatch(deleteItemAsync(item._id));
        await dispatch(getItemsAsync(""));
    };

    const handleCancel = () => {
        setEditing(false);
    };

    return (
        <Wrapper key={item.id}>
            <h1 style={{ color: "#006400" }}>{item.name}</h1>
            <p>
                <b>Description:</b> {item.description}
            </p>
            <p>
                <b>Price (in CAD):</b> {item.price}
            </p>
            <p>
                <b>Country of Origin:</b> {item.origin}
            </p>
            {item.unique ? (
                <p>
                    <b style={{ color: "red" }}>UNIQUE</b>
                </p>
            ) : null}
            <a href={item.image} target="_blank" rel="noopener noreferrer">
                <img
                    src={item.image}
                    alt={item.name}
                    style={{ width: "100px" }}
                />
            </a>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "8px",
                }}
            >
                <Button
                    type="button"
                    variant="outlined"
                    sx={{
                        ":hover": {
                            bgcolor: "darkGreen",
                            color: "white",
                            border: "1.5px solid darkGreen",
                        },
                        bgcolor: "white",
                        color: "darkGreen",
                        border: "1.5px solid darkGreen",
                        marginRight: "4px",
                    }}
                    onClick={handleEdit}
                >
                    Edit
                </Button>
                <Button
                    type="button"
                    variant="outlined"
                    sx={{
                        ":hover": {
                            bgcolor: "red",
                            color: "white",
                            border: "1.5px solid red",
                        },
                        bgcolor: "white",
                        color: "red",
                        border: "1.5px solid red",
                        marginLeft: "4px",
                    }}
                    onClick={() => handleDelete(item)}
                >
                    Delete
                </Button>
            </div>
            {editing ? (
                <EditForm
                    item={item}
                    onSave={handleSave}
                    onCancel={handleCancel}
                />
            ) : null}
        </Wrapper>
    );
};

export default ItemRow;
