import React, { useEffect, useState } from "react";
import Router from "./Router";

function App() {
    const [message, setMessage] = useState("");

    useEffect(() => {
        fetch("https://inventory-management-j6zj.onrender.com")
            .then((res) => res.json())
            .then((data) => setMessage(data.message));
    }, []);

    return (
        <div className="App">
            <h1>{message}</h1>
            <Router />
        </div>
    );
}

export default App;
