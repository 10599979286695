import React, { useState } from "react";
import "../styles/Style.css";
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import styled from "@emotion/styled";
import { useDispatch } from "react-redux";
import { addItemAsync, getItemsAsync } from "../redux/items/thunks";

const StyledContainer = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: space-around;
`;

export const ItemInput = () => {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState("");
    const [image, setImage] = useState("");
    const [origin, setOrigin] = useState("South Korea");
    const [unique, setUnique] = useState(false);

    const dispatch = useDispatch();

    const handleSubmit = async (e) => {
        e.preventDefault();
        await dispatch(
            addItemAsync({ name, description, price, image, origin, unique })
        );
        await dispatch(getItemsAsync(""));
        setName("");
        setDescription("");
        setPrice("");
        setImage("");
        setOrigin("South Korea");
        setUnique(false);
    };

    return (
        <StyledContainer
            sx={{ margin: "20px" }}
            component="form"
            onSubmit={handleSubmit}
        >
            <Div>
                <b>Name:</b>
                <TextField
                    sx={{ backgroundColor: "#FBFBFB", marginLeft: "20px" }}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </Div>
            <Div>
                <b>Description:</b>
                <TextField
                    sx={{ backgroundColor: "#FBFBFB", marginLeft: "20px" }}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </Div>
            <Div>
                <b>Price (in CAD):</b>
                <TextField
                    sx={{ backgroundColor: "#FBFBFB", marginLeft: "20px" }}
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                />
            </Div>
            <Div>
                <b>Image (URL):</b>
                <TextField
                    sx={{ backgroundColor: "#FBFBFB", marginLeft: "20px" }}
                    value={image}
                    onChange={(e) => setImage(e.target.value)}
                />
            </Div>
            <Div>
                <b>Country of Origin:</b>
                <div>
                    <FormControl>
                        <Select
                            value={origin}
                            onChange={(e) => setOrigin(e.target.value)}
                            sx={{
                                backgroundColor: "#FBFBFB",
                                marginLeft: "20px",
                            }}
                        >
                            <MenuItem value="South Korea">South Korea</MenuItem>
                            <MenuItem value="Canada">Canada</MenuItem>
                            <MenuItem value="USA">USA</MenuItem>
                            <MenuItem value="Others">Others</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </Div>
            <Div>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={unique}
                            onChange={(e) => setUnique(e.target.checked)}
                            color="primary"
                        />
                    }
                    label={<b>Unique Item</b>}
                />
            </Div>
            <Button
                sx={{
                    ":hover": {
                        bgcolor: "darkGreen",
                    },
                    backgroundColor: "green",
                }}
                type="submit"
                variant="contained"
                className="submitBtn"
            >
                Add
            </Button>
        </StyledContainer>
    );
};

export default ItemInput;

const Div = styled.div`
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
`;
