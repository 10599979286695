import { createSlice } from "@reduxjs/toolkit";
import { REQUEST_STATE } from "../utils";
import {
    addItemAsync,
    getItemsAsync,
    deleteItemAsync,
    deleteItemsAsync,
    editItemAsync,
} from "./thunks";

const INITIAL_STATE = {
    list: [],
    getItems: REQUEST_STATE.IDLE,
    addItem: REQUEST_STATE.IDLE,
    error: null,
};

const itemsSlice = createSlice({
    name: "items",
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getItemsAsync.pending, (state) => {
                state.getItems = REQUEST_STATE.PENDING;
                state.error = null;
            })
            .addCase(getItemsAsync.fulfilled, (state, action) => {
                state.getItems = REQUEST_STATE.FULFILLED;
                state.list = action.payload;
            })
            .addCase(getItemsAsync.rejected, (state, action) => {
                state.getItems = REQUEST_STATE.REJECTED;
                state.error = action.error;
            })
            .addCase(addItemAsync.pending, (state) => {
                state.addItem = REQUEST_STATE.PENDING;
                state.error = null;
            })
            .addCase(addItemAsync.fulfilled, (state, action) => {
                state.addItem = REQUEST_STATE.FULFILLED;
                state.list.push(action.payload);
            })
            .addCase(addItemAsync.rejected, (state, action) => {
                state.addItem = REQUEST_STATE.REJECTED;
                state.error = action.error;
                alert("Entering the item name is required.");
            })
            .addCase(deleteItemAsync.pending, (state) => {
                state.deleteItem = REQUEST_STATE.PENDING;
                state.error = null;
            })
            .addCase(deleteItemAsync.fulfilled, (state, action) => {
                state.deleteItem = REQUEST_STATE.FULFILLED;
                // Remove the deleted item from the state.list
                state.list = state.list.filter(
                    (item) => item.id !== action.payload.id
                );
            })
            .addCase(deleteItemAsync.rejected, (state, action) => {
                state.deleteItem = REQUEST_STATE.REJECTED;
                state.error = action.error;
                alert("Failed to delete the item. Please try again.");
            })
            .addCase(deleteItemsAsync.pending, (state) => {
                state.deleteItems = REQUEST_STATE.PENDING;
                state.error = null;
            })
            .addCase(deleteItemsAsync.fulfilled, (state) => {
                state.deleteItems = REQUEST_STATE.FULFILLED;
                state.list = [];
            })
            .addCase(deleteItemsAsync.rejected, (state, action) => {
                state.deleteItems = REQUEST_STATE.REJECTED;
                state.error = action.error;
                alert("Failed to delete all items. Please try again.");
            })
            .addCase(editItemAsync.pending, (state) => {
                state.editItem = REQUEST_STATE.PENDING;
                state.error = null;
            })
            .addCase(editItemAsync.fulfilled, (state, action) => {
                state.editItem = REQUEST_STATE.FULFILLED;
                // Find the edited item in the state.list and update its properties
                state.list = state.list.map((item) =>
                    item.id === action.payload.id ? action.payload : item
                );
            })
            .addCase(editItemAsync.rejected, (state, action) => {
                state.editItem = REQUEST_STATE.REJECTED;
                state.error = action.error;
                alert("Failed to edit the item. Please try again.");
            });
    },
});

export default itemsSlice.reducer;
