import React, { useState } from "react";
import "../styles/Style.css";
import styled from "styled-components";

const EditForm = ({ item, onSave, onCancel }) => {
    const [name, setName] = useState(item.name);
    const [description, setDescription] = useState(item.description);
    const [price, setPrice] = useState(item.price);
    const [image, setImage] = useState(item.image);
    const [origin, setOrigin] = useState(item.origin);
    const [unique, setUnique] = useState(item.unique);

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const updatedItem = {
            ...item,
            name,
            description,
            price,
            image,
            origin,
            unique,
        };
        onSave(updatedItem);
    };

    const handleFormCancel = () => {
        onCancel();
    };

    return (
        <form style={{ marginTop: "10px" }} onSubmit={handleFormSubmit}>
            <label>
                <b>Name: </b>
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </label>
            <label>
                <b>Description: </b>
                <input
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </label>
            <label>
                <b>Price (CAD): </b>
                <input
                    type="text"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                />
            </label>
            <label>
                <b>Origin: </b>
                <select
                    value={origin}
                    onChange={(e) => setOrigin(e.target.value)}
                >
                    <option value="South Korea">South Korea</option>
                    <option value="Canada">Canada</option>
                    <option value="USA">USA</option>
                    <option value="Others">Others</option>
                </select>
            </label>
            <Div>
                <b>Unique?</b>
                <span>
                    <input
                        id="checkbox"
                        type="checkbox"
                        checked={unique}
                        onChange={(e) => setUnique(e.target.checked)}
                    />
                </span>
            </Div>
            <label>
                <b>Image (URL): </b>
                <input
                    type="text"
                    value={image}
                    onChange={(e) => setImage(e.target.value)}
                />
            </label>
            <Buttons>
                <button style={{ marginRight: "3px" }} type="submit">
                    Save
                </button>
                <button
                    style={{ marginLeft: "3px" }}
                    type="button"
                    onClick={handleFormCancel}
                >
                    Cancel
                </button>
            </Buttons>
        </form>
    );
};

export default EditForm;

const Div = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-alignment: center;
    margin-top: 5px;
    margin-bottom: 5px;
`;

const Buttons = styled.div`
    margin-top: 5px;
`;
