import React from "react";

// React Tutorial (https://react.vlpt.us/styling/)
function Wrapper({ children }) {
    const style = {
        border: "3px solid black",
        padding: "16px",
        width: "240px",
        marginBottom: "10px",
        backgroundColor: "beige",
        textAlign: "center",
    };
    return <div style={style}>{children}</div>;
}

export default Wrapper;
