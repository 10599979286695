import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./pages/Main";
import Item from "./pages/Item";

function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/items" element={<Item />} />
            </Routes>
        </BrowserRouter>
    );
}

export default Router;
