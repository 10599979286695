const addItem = async ({ name, description, price, image, origin, unique }) => {
    try {
        const response = await fetch("https://inventory-management-j6zj.onrender.com/items", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ name, description, price, image, origin, unique }),
        });

        if (!response.ok) {
            const errorMsg = await response.json();
            throw new Error(errorMsg.message);
        }

        return response.json();
    } catch (error) {
        throw new Error(error.message);
    }
};

const getItems = async (origin) => {
    const response = await fetch(`https://inventory-management-j6zj.onrender.com/items?origin=${origin}`, {
        method: "GET",
    });
    return response.json();
};

const deleteItem = async (itemId) => {
    const response = await fetch(`https://inventory-management-j6zj.onrender.com/items/${itemId}`, {
        method: "DELETE",
    });

    const data = await response.json();
    if (!response.ok) {
        const errorMsg = data?.message;
        throw new Error(errorMsg);
    }

    return data;
};

const deleteItems = async () => {
    const response = await fetch("https://inventory-management-j6zj.onrender.com/items", {
        method: "DELETE",
    });

    const data = await response.json();
    if (!response.ok) {
        const errorMsg = data?.message;
        throw new Error(errorMsg);
    }

    return data;
};

const editItem = async (item) => {
    const response = await fetch(`https://inventory-management-j6zj.onrender.com/items/${item.id}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(item),
    });

    const data = await response.json();
    if (!response.ok) {
        const errorMsg = data?.message;
        throw new Error(errorMsg);
    }

    return data;
};

export default {
    addItem,
    getItems,
    deleteItem,
    deleteItems,
    editItem,
};
