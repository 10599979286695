import { createAsyncThunk } from "@reduxjs/toolkit";
import { actionTypes } from "./actionTypes";
import ItemService from "./service";

export const getItemsAsync = createAsyncThunk(
    actionTypes.GET_ITEMS,
    async (origin) => {
        return await ItemService.getItems(origin);
    }
);

export const addItemAsync = createAsyncThunk(
    actionTypes.ADD_ITEM,
    async ({ name, description, price, image, origin, unique }) => {
        return await ItemService.addItem({
            name,
            description,
            price,
            image,
            origin,
            unique,
        });
    }
);

export const deleteItemAsync = createAsyncThunk(
    actionTypes.DELETE_ITEM,
    async (itemId) => {
        return await ItemService.deleteItem(itemId);
    }
);

export const deleteItemsAsync = createAsyncThunk(
    actionTypes.DELETE_ITEMS,
    async () => {
        return await ItemService.deleteItems();
    }
);

export const editItemAsync = createAsyncThunk(
    actionTypes.EDIT_ITEM,
    async (item) => {
        return await ItemService.editItem(item);
    }
);
