import React from "react";
import { Link } from "react-router-dom";
import ViewListIcon from "@mui/icons-material/ViewList";

function Main() {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
            }}
        >
            <Link to="/items">
                <ViewListIcon
                    sx={{
                        fontSize: 100,
                    }}
                    color="success"
                />
                <br></br>
                Inventory Management
            </Link>
        </div>
    );
}

export default Main;
