import React from "react";
import { Grid } from "@mui/material";
import ItemInput from "../components/ItemInput";
import ItemList from "../components/ItemList";

function Item() {
    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} className="sidebar">
                    <ItemInput />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <ItemList />
                </Grid>
            </Grid>
        </div>
    );
}

export default Item;
